.attendance-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

.attendance-title {
  flex-grow: 1;
}

.attendance-remove-btn {
  border: none;
  padding: 5px 10px;
  text-align: center;
  background-color: transparent;
  color: rgb(143, 24, 24);
}

.attendance-add-btn {
  border: 0;
  padding: 5px 10px;
  text-align: center;
  color: rgb(11, 90, 11);
  display: block;
  font-weight: 600;
  margin-left: auto;
}
