*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  line-height: 1.4;
}

body {
  display: flex;
  flex-direction: column;
}

.uni-green_btn {
  background-color: rgba(25, 80, 44, 0.895) !important;
  color: #fff !important;
}

.paper-bg {
  background-image: linear-gradient(
      45deg,
      rgba(236, 240, 241, 0.5),
      rgba(236, 240, 241, 0.342)
    ),
    url("./assets/img/bg-paper.webp");
  padding: 0.3rem;
  margin-bottom: 1rem;
}
