.summary-card_box {
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.562),
      rgba(0, 0, 0, 0.897)
    ),
    url("../../../assets/img/analytics-bg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem 0.3rem 2.2rem 0.3rem;
  margin-bottom: 1rem;
}
