.personal-finance_box {
  background-image: linear-gradient(
      45deg,
      rgba(236, 240, 241, 0.5),
      rgba(236, 240, 241, 0.342)
    ),
    url("../../../assets/img/bg-paper.webp");
  padding: 0.3rem;
  margin-bottom: 1rem;
}
